import Layout from "./pages/Layout/index";
import { Suspense, lazy } from "react";
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Loader from "./components/Loader";

const LazyLoadComponent = lazy(() => import("./pages/Layout/index"));

export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <LazyLoadComponent />
      </Suspense>
    </BrowserRouter>
  )
}
