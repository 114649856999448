// Import Montserrat font weights.
import "@fontsource/montserrat/400.css"; // Weight 400
import "@fontsource/montserrat/500.css"; // Weight 500
import "@fontsource/montserrat/700.css"; // Weight 700

// Import Cabin font.
import "@fontsource/cabin/400.css"; // Regular weight

import "./variables.scss";

import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Define your SCSS variables
// Define your colors
const colors = {
  cream: '#FFFCF2',
  grayishBeige: '#CCC5B9',
  charcoal: '#403D39',
  almostBlack: '#252422',
  orange: '#EB5E28',
  orangeLight: '#FFD0A3',
  orangeDark: '#B13C06',
  purple: '#6E44FF',
  purpleLight: '#B0A3FF',
  purpleDark: '#3F25E5',
  teal: '#32A897',
};

// Create a theme instance.
let theme = createTheme({
  typography: {
    fontFamily: '"Cabin", "Arial", sans-serif',
    h1: {
      fontFamily: '"Montserrat", "Arial", sans-serif',
      fontWeight: 700,
      color: colors.almostBlack
    },
    h2: {
      fontFamily: '"Montserrat", "Arial", sans-serif',
      fontWeight: 700,
      color: colors.almostBlack
    },
    h3: {
      fontFamily: '"Montserrat", "Arial", sans-serif',
      fontWeight: 500,
      color: colors.almostBlack
    },
    h4: {
      fontFamily: '"Montserrat", "Arial", sans-serif',
      fontWeight: 500,
      color: colors.almostBlack
    },
    h5: {
      fontFamily: '"Montserrat", "Arial", sans-serif',
      fontWeight: 400,
      color: colors.almostBlack
    },
    h6: {
      fontFamily: '"Montserrat", "Arial", sans-serif',
      fontWeight: 400,
      color: colors.almostBlack
    },
    button: {
      fontFamily: '"Montserrat", "Arial", sans-serif',
      fontWeight: 500,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;