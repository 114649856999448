import React from 'react';
import './index.scss'; // Assuming you have an SCSS file for styles

const Loader = () => {
    return (
        <div className="loaderContainer">
            <div className="pulseLoader"></div>
        </div>
    );
};

export default Loader